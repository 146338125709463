<template>
  <div>
    <v-list dense subheader class="my-0 py-0">
      <v-subheader style="font-size: 14px"
        ><v-icon class="mr-2" small>mode_of_travel</v-icon>Transport
        Details</v-subheader
      >
      <v-divider></v-divider>
      <v-list-item v-if="booking.modeOfTransport == 'OCEAN'">
        <v-list-item-action>
          <v-icon v-if="!booking.shippingLine" small>directions_boat</v-icon>
          <v-avatar
            :color="
              booking.shippingLine && booking.shippingLine.logo
                ? 'white'
                : 'secondary'
            "
            size="32"
            v-else
          >
            <v-img
              v-if="booking.shippingLine && booking.shippingLine.logo"
              :src="booking.shippingLine.logo"
              contain
            ></v-img>
            <v-icon v-else small>directions_boat</v-icon>
          </v-avatar>
        </v-list-item-action>
        <v-list-item-content v-if="booking.vessel">
          <v-list-item-title style="font-size: 12px">
            {{ booking.vessel.name }}
          </v-list-item-title>
          <v-list-item-subtitle style="font-size: 11px">
            <u
              style="cursor: pointer"
              @click="voyageNumberModal = true"
              v-if="booking.mainCarriageConveyanceNumber"
              >Voyage No: {{ booking.mainCarriageConveyanceNumber }}</u
            >
            <u style="cursor: pointer" @click="voyageNumberModal = true" v-else
              >No Voyage No</u
            >
          </v-list-item-subtitle>
          <v-list-item-subtitle style="font-size: 11px">
            IMO Number: {{ booking.vessel.imoNumber }}
</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content v-else>
          <v-list-item-content>
            <v-list-item-title style="font-size: 12px"
              >Add Vessel</v-list-item-title
            >
            <v-list-item-subtitle style="font-size: 11px"
              ><i>(Required)</i></v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item-content>
        <v-list-item-action>
          <v-row justify="center">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  color="teal"
                  @click="openSailingSchedules()"
                >
                  <v-icon>search</v-icon></v-btn
                >
              </template>
              <span style="font-size: 12px">Search Sailing Schedules</span>
            </v-tooltip>
          </v-row>
        </v-list-item-action>
      </v-list-item>
      <v-list-item style="height: 30px"
        @click="shippingLineModal = true"
        v-if="booking.modeOfTransport == 'OCEAN'"
      >
        <v-list-item-action>
          <v-icon v-if="!booking.shippingLine" small>directions_boat</v-icon>
          <v-avatar
            v-else
            :color="
              booking.shippingLine && booking.shippingLine.logo
                ? 'white'
                : 'secondary'
            "
            size="32"
          >
            <v-img
              v-if="booking.shippingLine && booking.shippingLine.logo"
              :src="booking.shippingLine.logo"
              contain
            ></v-img>
            <h3 v-else>
              {{ booking.shippingLine.name.charAt(0) }}
            </h3>
          </v-avatar>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title
            v-if="booking.shippingLine"
            style="font-size: 12px"
            >{{ booking.shippingLine.name }}</v-list-item-title
          >
          <v-list-item-title v-else>-</v-list-item-title>
          <v-list-item-subtitle style="font-size: 11px"
            >Shipping Line</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="vesselOperatorModal = true" style="height: 30px">
        <v-list-item-action v-if="!booking.vesselOperator">
          <v-icon small>directions_boat</v-icon>
        </v-list-item-action>
        <v-list-item-avatar v-else>
          <v-avatar color="white" size="32" v-if="booking.vesselOperator">
            <v-img
              v-if="booking.vesselOperator && booking.vesselOperator.logo"
              :src="booking.vesselOperator.logo"
              contain
            ></v-img>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            style="font-size: 12px"
            v-if="booking.vesselOperator"
            >{{ booking.vesselOperator.name }}</v-list-item-title
          >
          <v-list-item-title v-else>-</v-list-item-title>
          <v-list-item-subtitle style="font-size: 11px"
            >Vessel Operator</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item
                      @click="editContractNumber()"
                    >
                      <v-list-item-action>
                        <v-icon small>description</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          v-if="booking.contractNumber"
                          style="font-size: 12px"
                        >
                          {{ booking.contractNumber }}
                        </v-list-item-title>
                        <v-list-item-title v-else style="font-size: 12px">
                          No Contract Selected
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-if="booking.contractParty"
                          style="font-size: 11px"
                        >
                          <v-icon class="mr-2" small color="grey"
                            >groups</v-icon
                          >
                          <span v-if="booking.contractParty.alias">
                            {{ booking.contractParty.alias }}
                          </span><span v-else>
                            {{ booking.contractParty.name }}
                            </span> 
                       
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 11px">
                          Selected Contract
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

      <div>
        <v-menu
          :close-on-content-click="true"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" style="height: 30px">
              <v-list-item-action>
                <v-icon small :color="booking.etd ? 'primary' : ''">today</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-if="booking.etd" style="font-size: 12px">
                  {{ $Format.formatDate(booking.etd).isoFormat }}
                </v-list-item-title>
                <v-list-item-title v-else> - </v-list-item-title>
                <v-list-item-subtitle style="font-size: 11px">
                  Estimated Departure
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-date-picker
            v-model="booking.etd"
            no-title
            scrollable
            color="primary"
            @change="$emit('bookingChange')"
          >
          </v-date-picker>
        </v-menu>
        <v-menu
          :close-on-content-click="true"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" style="height: 30px">
              <v-list-item-action>
                <v-icon small :color="booking.atd ? 'primary' : ''">today</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-if="booking.atd" style="font-size: 12px">
                  {{ $Format.formatDate(booking.atd).isoFormat }}
                </v-list-item-title>
                <v-list-item-title v-else> - </v-list-item-title>
                <v-list-item-subtitle style="font-size: 11px">
                  Actual Departure
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-date-picker
            v-model="booking.atd"
            no-title
            scrollable
            color="primary"
            @change="$emit('bookingChange')"
          >
          </v-date-picker>
        </v-menu>
        <!-- <v-col cols="12" sm="12"> -->
        <v-menu
          :close-on-content-click="true"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" style="height: 30px">
              <v-list-item-action>
                <v-icon small :color="booking.eta ? 'primary' : ''">today</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-if="booking.eta" style="font-size: 12px">
                  {{ $Format.formatDate(booking.eta).isoFormat }}
                </v-list-item-title>
                <v-list-item-title v-else> - </v-list-item-title>
                <v-list-item-subtitle style="font-size: 11px">
                  Estimated Arrival
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-date-picker
            v-model="booking.eta"
            no-title
            scrollable
            color="primary"
            @change="$emit('bookingChange')"
          >
          </v-date-picker>
        </v-menu>
        <v-menu
          :close-on-content-click="true"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on" style="height: 30px">
              <v-list-item-action>
                <v-icon small :color="booking.ata ? 'primary' : ''">today</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-if="booking.ata" style="font-size: 12px">
                  {{ $Format.formatDate(booking.ata).isoFormat }}
                </v-list-item-title>
                <v-list-item-title v-else> - </v-list-item-title>
                <v-list-item-subtitle style="font-size: 11px">
                  Actual Arrival
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-date-picker
            v-model="booking.ata"
            no-title
            scrollable
            color="primary"
            @change="$emit('bookingChange')"
          >
          </v-date-picker>
        </v-menu>
        <!-- </v-col> -->
      </div>
    </v-list>

    <v-dialog
      v-model="shippingLineModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px"
            >Set Carrier</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="shippingLineModal = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            dense
            outlined
            rounded
            prepend-inner-icon="search"
            autofocus
            placeholder="Search"
            clearable
            v-model="searchShippingLine"
          ></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="carrier in filterShippingLines"
              :key="carrier.id"
              @click="setShippingLine(carrier)"
              style="height: 45px"
            >
              <v-list-item-action>
                <v-avatar v-if="carrier.logo" color="white" size="32">
                  <v-img :src="carrier.logo" contain></v-img>
                </v-avatar>
                <v-avatar v-else-if="carrier.name" color="secondary" size="32">
                  <h3>{{ carrier.name.charAt(0) }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px">{{
                  carrier.name
                }}</v-list-item-title>
                <v-list-item-subtitle style="font-size: 11px">{{
                  carrier.friendlyName
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="sailingScheduleError"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-text class="text-left mt-3 pt-5">
          <p style="font-size: 16px">
            Please select make sure to select the following before continuing:
          </p>
          <ul>
            <li v-if="!booking.portOfLoadValue">Port of Load (POL)</li>
            <li v-if="!booking.portOfDischargeValue">
              Port of Discharge (POD)
            </li>
          </ul>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="blue"
              class="mx-2"
              text
              small
              @click="sailingScheduleError = false"
              >Okay</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="sailingScheduleModal"
      persistent
      width="90vw"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            <v-icon class="mr-2" color="secondary">directions_boat</v-icon>
            Search Sailing Schedules
          </v-toolbar-title>
          <v-chip
            v-if="booking.portOfLoadValue"
            small
            outlined
            style="border: none"
            class="ml-2"
          >
            <v-avatar size="24" left>
              <v-img
                contain
                v-if="booking.portOfLoadValue"
                :src="`https://cdn.loglive.io/flags/4x3/${booking.portOfLoadValue
                  .substring(0, 2)
                  .toLowerCase()}.svg`"
              ></v-img>
            </v-avatar>
            {{ booking.portOfLoadValue }}
          </v-chip>

          <v-icon class="mx-3" color="grey" small>east</v-icon>
          <v-chip
            v-if="booking.portOfDischargeValue"
            small
            outlined
            style="border: none"
          >
            <v-avatar size="24" left>
              <v-img
                contain
                v-if="booking.portOfDischargeValue"
                :src="`https://cdn.loglive.io/flags/4x3/${booking.portOfDischargeValue
                  .substring(0, 2)
                  .toLowerCase()}.svg`"
              ></v-img>
            </v-avatar>
            {{ booking.portOfDischargeValue }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="sailingScheduleModal = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <ScheduleModal
            :export="booking.movementType == 'EXPORT'"
            :portOfLoadValue="booking.portOfLoadValue"
            :finalDestinationValue="booking.portOfDischargeValue"
            :shippingLines="shippingLines"
            @select="selectSchedule"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="vesselOperatorModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px"
            >Set Vessel Operator</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="vesselOperatorModal = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            dense
            outlined
            rounded
            prepend-inner-icon="search"
            autofocus
            placeholder="Search"
            clearable
            v-model="searchVesselOperator"
          ></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="carrier in filterVesselOperator"
              :key="carrier.id"
              @click="setVesselOperator(carrier)"
              style="height: 45px"
            >
              <v-list-item-action class="mx-0 px-0 mr-2">
                <v-avatar
                  :color="carrier.logo ? 'white' : 'secondary'"
                  size="32"
                >
                  <v-img
                    v-if="carrier.logo"
                    :src="carrier.logo"
                    contain
                  ></v-img>
                  <h3 v-else>{{ carrier.name.charAt(0) }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px">{{
                  carrier.name
                }}</v-list-item-title>
                <v-list-item-subtitle style="font-size: 11px">{{
                  carrier.friendlyName
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="contractModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            Set Contract
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="primary" @click="booking.contractNumber = contractNumber, $emit('bookingChange'),contractModal = false">
            <v-icon small>save</v-icon>
          </v-btn>
          <v-btn icon color="redPop" @click="contractModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text :key="contractKey">
          <v-row justify="center">
            <v-col cols="12">
              <v-list dense style="max-height: 50vh; overflow-y: auto">
                <!-- <v-subheader style="font-size: 14px"
                  >Manual Contract Entry</v-subheader
                > -->
                <!-- <v-divider></v-divider> -->
             <v-list-item @click="contractOwnerModal = true">
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            booking.contractParty &&
                            booking.contractParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              booking.contractParty &&
                              booking.contractParty.logo
                            "
                            :src="booking.contractParty.logo"
                            contain
                          />
                          <h3 v-else-if="booking.contractParty">
                            {{ booking.contractParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="booking.contractParty"
                        >
                          <span
                            v-if="booking.contractParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ booking.contractParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ booking.contractParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Contract Owner
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                <v-text-field
                  class="my-3 mx-1"
                  label="Contract Number"
                  outlined
                  dense
                  clearable
                  hide-details
                  v-model="contractNumber"
                >
                  <template v-slot:label>
                    <span style="font-size: 13px"> Contract Number </span>
                  </template></v-text-field
                >
              </v-list>
            </v-col>
          
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="voyageNumberModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat dense color="transparent">
          <v-spacer></v-spacer>
          <v-btn text @click="voyageNumberModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            label="Voyage No"
            v-model="booking.mainCarriageConveyanceNumber"
            outlined
            dense
            clearable @change="$emit('bookingChange')"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="contractOwnerModal" width="500px" persistent>
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title style="font-size: 16px"> Contract Owner </v-toolbar-title>
          <v-spacer />
          <v-btn icon color="redPop" @click="contractOwnerModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            prepend-inner-icon="search"
            placeholder="Search"
            v-model="searchContractOwner"
            hide-details
            outlined
            dense
            autofocus
            clearable
          ></v-text-field>
          <v-list dense subheader style="max-height: 400px; overflow-y: auto">
            <div v-for="(item, index) in availableContractParties" :key="index">
              <div v-if="item.type == 'SUBHEADER'">
                <v-divider></v-divider>
                <v-subheader style="font-size: 14px">
                  {{ item.name }}
                </v-subheader>
                <v-divider></v-divider>
              </div>
              <v-list-item
                v-else
                @click="setContractOwner(item)"
              >
                <v-list-item-action>
                  <v-avatar
                    :color="item.logo ? 'white' : 'secondary'"
                    size="32"
                  >
                    <v-img v-if="item.logo" :src="item.logo" contain />
                    <h3 v-else>
                      {{ item.name.charAt(0) }}
                    </h3>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ScheduleModal from "../Bookings/Dialogs/SailingSchedule.vue";

export default {
  props: {
    booking: {
      type: Object,
      required: true,
    },
    organisations: {
      type: Array,
      required: true,
    },
  },
  components: {
    ScheduleModal,
  },
  data: () => ({
    airlines: [],
    chargeOptions: [
      { name: "Buyer", value: "buyer" },
      { name: "Forwarder", value: "forwarder" },
      { name: "Shipper", value: "shipper" },
      { name: "Consignee", value: "consignee" },
      { name: "1st Notify Party", value: "firstNotify" },
      { name: "2nd Notify Party", value: "secondNotify" },
      { name: "On Behalf of Shipper", value: "onBehalfShipper" },
      { name: "Customer", value: "customer" },
    ],
    contractNumber: null,
    contractOwnerModal: false,
    contractModal: false,
    contractKey: 100,
    partyTypes: [
      {
        name: "Bank",
        value: "bank",
        allowLink: false,
        icon: "account_balance",
        conditionalFields: ["letterOfCredit"],
      },
      {
        name: "Shipper",
        value: "shipper",
        allowLink: true,
        icon: "directions_boat_filled",
        linkedValue: "onBehalfShipper",
      },
      {
        name: "Forwarder",
        value: "forwarder",
        allowLink: true,
        icon: "fast_forward",
        linkedValue: "onBehalfForwarder",
      },
      {
        name: "Buyer",
        value: "buyer",
        allowLink: true,
        icon: "shopping_cart",
        linkedValue: "onBehalfBuyer",
      },
      {
        name: "Consignee",
        value: "consignee",
        allowLink: true,
        icon: "local_shipping",
        linkedValue: "onBehalfConsignee",
      },
      {
        name: "Courier",
        value: "courierParty",
        allowLink: false,
        icon: "local_post_office",
      },
      {
        name: "Notify",
        value: "firstNotify",
        allowLink: false,
        icon: "notifications",
      },
      {
        name: "2nd Notify Party",
        value: "secondNotify",
        allowLink: false,
        icon: "notifications",
      },
      {
        name: "Customer",
        value: "customer",
      },
      {
        name: "Contract Party",
        value: "contractParty",
      },
    ],
    sailingScheduleError: false,
    sailingScheduleModal: false,
    search: null,
    searchContract: null,
    searchContractOwner: null,
    searchShippingLine: null,
    searchVesselOperator: null,
    shippingLines: [],
    shippingLineModal: false,
    suggestedContracts: [],
    vesselOperatorModal: false,
    vesselOperators: [],
    voyageNumberModal: false,
  }),
  computed: {
    availableContractParties() {
      let result = [
        {
          type: "SUBHEADER",
          name: "Profile Parties",
        },
        {
          id: this.$store.state.currentOrg.id,
          name: this.$store.state.currentOrg.name,
          alias: this.$store.state.currentOrg.alias,
          logo: this.$store.state.currentOrg.logo,
          type: 'Current Organisation'
        }
      ];
      for (let party of this.partyTypes) {
        if (this.booking[party.value]) {
          result.push({
            type: party.name,
            name: this.booking[party.value].name,
            id: this.booking[party.value].id,
            friendlyName:
              this.booking[party.value].friendlyName ??
              this.booking[party.value].alias,
            logo: this.booking[party.value].logo,
          });
        }
      }
      result.push({
        type: "SUBHEADER",
        name: "Other Parties",
      });
      let organisations = this.organisations.map(x=>x.relatedOrganisation).filter((x) => !result.map((y) => y.id).includes(x.id))
      organisations = organisations.sort((a,b)=>a.name.localeCompare(b.name))
      result = [
        ...result,
        ...organisations          
      ];
      if (this.searchContractOwner) {
        result = result.filter(
          (x) =>
            x.type != "SUBHEADER" &&
            (x.name.toLowerCase().includes(this.searchContractOwner.toLowerCase()) ||
            (x.alias && x.alias.toLowerCase().includes(this.searchContractOwner.toLowerCase())))
        );
      }
      return result;
    },
    filterSuggestedContracts() {
      let result = this.suggestedContracts;
      if (this.searchContract) {
        result = result.filter(
          (x) =>
            (x.contracts[0] &&
              x.contracts[0].contractNo
                .toLowerCase()
                .includes(this.searchContract.toLowerCase())) ||
            (x.contractOwner &&
              x.contractOwner.name
                .toLowerCase()
                .includes(this.searchContract.toLowerCase())) ||
            (x.contractOwner &&
              x.contractOwner.alias
                .toLowerCase()
                .includes(this.searchContract.toLowerCase()))
        );
      }
      return result;
    },
    filterShippingLines() {
      let result = this.booking.movementType == 'OCEAN' ? this.shippingLines : this.airlines;
      if (this.searchShippingLine) {
        result = result.filter(
          (x) =>
            x.name
              .toLowerCase()
              .includes(this.searchShippingLine.toLowerCase()) ||
            (x.friendlyName &&
              x.friendlyName
                .toLowerCase()
                .includes(this.searchShippingLine.toLowerCase()))
        );
      }
      return result;
    },
    filterVesselOperator() {
      let result = this.vesselOperators;
      if (this.searchVesselOperator) {
        result = result.filter(
          (x) =>
            x.name
              .toLowerCase()
              .includes(this.searchVesselOperator.toLowerCase()) ||
            (x.friendlyName &&
              x.friendlyName
                .toLowerCase()
                .includes(this.searchVesselOperator.toLowerCase()))
        );
      }
      return result;
    },
  },
  async created() {
    this.shippingLines = await this.$API.getShippingLines();
    this.airlines = await this.$API.getAirLines();
    this.vesselOperators = await this.$API.getVesselOperators();
  },
  methods: {
    async editContractNumber() {
      this.contractNumber = this.booking.contractNumber ?? null
      let containers = this.booking.bookingContainers
        ? this.booking.bookingContainers.filter(
            (x) =>
              !x.isDeleted &&
              x.status != "Ghost" &&
              x.status != "Cancelled" &&
              x.bookingId == this.booking.id
          )
        : [];
      let products = [
        ...new Set(
          [].concat
            .apply(
              [],
              containers.map((x) => x.containerProducts)
            )
            .map((x) => x.productId)
        ),
      ];
      let containerTypes = [
        ...new Set(
          [].concat.apply(
            [],
            containers.map((x) => x.containerTypeCode)
          )
        ),
      ];
      this.suggestedContracts = [];
      if (
        this.booking.etd &&
        containerTypes.length > 0 &&
        products.length > 0 &&
        this.booking.portOfLoadValue &&
        this.booking.portOfDischargeValue &&
        this.booking.shippingLineId
      ) {
        let contracts = await this.$API.getBookingContracts({
          params: {
            origin: this.booking.portOfLoadValue,
            originCountry: this.booking.portOfLoadValue
              ? this.booking.portOfLoadValue.substring(0, 2)
              : null,
            destination: this.booking.portOfDischargeValue,
            destinationCountry: this.booking.portOfDischargeValue
              ? this.booking.portOfDischargeValue.substring(0, 2)
              : null,
            contractPartyId: this.booking.shippingLineId,
            date: this.booking.etd,
            containerTypes: containerTypes,
            products: products,
          },
        });
        this.suggestedContracts = contracts;
      }

      this.contractModal = true;
    },
    getContractParty(party) {
      if (this.booking[party]) {
        return this.booking[party].name;
      } else return null;
    },
    openSailingSchedules() {
      if (this.booking.portOfLoadValue && this.booking.portOfDischargeValue) {
        this.sailingScheduleModal = true;
      } else {
        this.sailingScheduleError = true;
      }
    },
    selectSchedule(schedule) {
      this.booking.carrier = schedule.carrier;
      this.booking.vessel = schedule.vessel;
      this.booking.vesselId = schedule.vesselId;
      this.booking.voyage = schedule.voyage;
      this.booking.sailingSchedule = schedule;
      this.booking.sailingScheduleId = schedule.id;
      this.booking.carrierScac = schedule.scac;
      this.booking.serviceName = schedule.serviceName;
      this.booking.eta = schedule.eta;
      this.booking.etd = schedule.etd;
      this.booking.atd = null;
      this.booking.ata = null;
      this.booking.shippingLine = schedule.shippingLine;
      this.booking.shippingLineId = schedule.shippingLineId;
      this.booking.carrierName = schedule.carrier;
      this.booking.mainCarriageVesselName = schedule.vesselDescription;
      this.booking.mainCarriageConveyanceNumber = schedule.voyage;
      this.booking.stackDate = schedule.stackDate;
      this.booking.stackDateId = schedule.stackDateId;
      this.booking.isTransshipment = !schedule.direct;
      this.booking.transportRouting = schedule.via;
      this.sailingScheduleModal = false;
    },

    async setContract(contract) {
      let parties = [
        "forwarder",
        "shipper",
        "customer",
        "onBehalfShipper",
        "onBehalfForwarder",
        "consignee",
        "buyer",
        "onBehalfBuyer",
        "onBehalfConsignee",
        "firstNotify",
        "secondNotify",
      ];
      let result = [];
      for (let party of parties) {
        if (this.booking[party]) {
          let obj = {
            role: party,
            ...this.booking[party],
          };
          result.push(obj);
        }
      }
      let owner = result.find((x) => x.id == contract.contractOwnerId);
      this.booking.contractId = contract.id;
      this.booking.contractOwner = owner ? owner.role : null;
      this.booking.contractNumber = contract.contracts[0].contractNo;
      this.booking.contractPartyId = contract.contractOwnerId;
      this.contractModal = false;
      this.$emit("bookingChange");
    },
    setContractOwner(party){
      this.booking.contractPartyId = party.id
      this.booking.contractParty = party
      this.contractOwnerModal = false
    },
    setShippingLine(item) {
      this.booking.shippingLine = item;
      this.booking.shippingLineId = item.id;
      this.booking.carrier = item;
      this.booking.carrierId = item.id;
      this.shippingLineModal = false;
    },
    setVesselOperator(item) {
      this.booking.vesselOperator = item;
      this.booking.vesselOperatorId = item.id;
      this.vesselOperatorModal = false;
      //   if (!this.booking.mainCarriageConveyanceNumber) {
      //     this.voyageNumberModal = true;
      //   }
      this.$emit("bookingChange");
    },
    setVessel(vessel) {
      this.booking.vessel = vessel;
      this.booking.vesselId = vessel.id;
      this.vesselModal = false;
      this.voyageNumberModal = true;
      this.$emit("bookingChange");
    },
  },
};
</script>