<template>
  <div
    class="innerContent"
    style="
      height: calc(100vh);
      width: 100%;
      min-width: 85%;
      max-width: 100%;
      background: var(--v-greyRaised-base) !important;
    "
  >
    <v-row class="ma-1 pa-2" no-gutters>
      <v-col cols="12" sm="12" md="4" lg="3">
        <v-card class="infoCards mx-1" flat>
          <v-card-text class="ma-0 pa-0">
            <TransportDetail :booking="booking" :organisations="organisations" @bookingChange="$emit('bookingChange')" />
          </v-card-text>
        </v-card>
        <v-card class="infoCards mx-1 mt-2 pt-1" flat>
          <v-card-text class="ma-0 pa-0">
            <v-list dense class="my-0 py-0" subheader :key="transportKey">
              <v-subheader style="font-size: 14px">
                <v-icon class="mr-2" small>local_shipping</v-icon>Transport
                Locations
              </v-subheader>
              <v-divider></v-divider>
              <v-list-item
                v-for="(location, index) in transportLocations"
                :key="index"
                style="height: 30px"
              >
                <v-list-item-action>
                  <v-chip small>{{ location.count }}</v-chip>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 12px">
                    {{ location.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 11px">
                    {{ location.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="transportLocations.length == 0">
                <v-list-item-content class="text-center">
                  <span style="color: grey; font-size: 11px"
                    >No locations set</span
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="8"
        lg="9"
        class="ml-0 pl-1"
        v-if="
          booking.sailingSchedule &&
          booking.sailingSchedule.sailingScheduleLegs.length > 0
        "
      >
        <v-card class="infoCards mb-0 pb-0" flat>
          <v-card-text class="ma-0 pa-0">
            <v-list dense class="my-0 py-0" subheader>
              <v-subheader style="font-size: 14px">
                <v-icon class="mr-2" small>track_changes</v-icon>Vessel Tracking
              </v-subheader>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="12" sm="4" class="ma-0 pa-0">
                  <v-list dense>
                    <v-list-item
                      v-for="leg in booking.sailingSchedule.sailingScheduleLegs"
                      :key="leg.id"
                    >
                      <v-list-item-action class="mr-1">
                        <v-chip small>{{ leg.leg }}</v-chip>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-chip
                            small
                            outlined
                            label
                            class="primaryText--text"
                            style="border: none"
                          >
                            <v-avatar size="24" left>
                              <v-img
                                contain
                                :src="`https://cdn.loglive.io/flags/4x3/${leg.portOfLoadValue
                                  .substring(0, 2)
                                  .toLowerCase()}.svg`"
                              ></v-img> </v-avatar
                            >{{ leg.portOfLoadValue }}
                          </v-chip>
                          <v-icon class="mx-3" color="grey" x-small
                            >east</v-icon
                          >
                          <v-chip
                            small
                            outlined
                            label
                            class="primaryText--text"
                            style="border: none"
                          >
                            <v-avatar size="24" left>
                              <v-img
                                contain
                                :src="`https://cdn.loglive.io/flags/4x3/${leg.portOfDischargeValue
                                  .substring(0, 2)
                                  .toLowerCase()}.svg`"
                              ></v-img> </v-avatar
                            >{{ leg.portOfDischargeValue }}
                          </v-chip>
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          <v-icon x-small color="grey" class="mr-1"
                            >directions_boat</v-icon
                          >
                          {{ leg.vesselDescription
                          }}<span v-if="leg.voyage" class="ml-1">
                            {{ leg.voyage }}</span
                          >
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 11px">
                          <v-icon x-small color="grey" class="mr-1"
                            >schedule</v-icon
                          >
                          {{ $Format.formatDate(leg.etd).isoFormat }} -
                          {{ $Format.formatDate(leg.eta).isoFormat }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" sm="8">
                  <gmap-map
                    :key="refreshMap"
                    :zoom.sync="zoom"
                    class="ma-0 pa-0 theMap"
                    id="map"
                    :options="{ styles: mapStyle, disableDefaultUI: true }"
                    :center.sync="mapCenter"
                    ref="mapRef"
                    style="width: 100%; height: 400px"
                  >
                    <div v-if="routes && routes.legs">
                      <gmap-polyline
                        v-for="(path, index) in routes.legs"
                        :key="index"
                        :path="path.pathData"
                        :editable="false"
                        ref="polyline"
                        :options="{
                          strokeColor: legColours[index] ?? '#2196f3',
                          strokeOpacity: 0.9,
                          strokeWeight: 3,
                        }"
                      >
                      </gmap-polyline>
                      <gmap-custom-marker
                        v-if="booking.vessel"
                        alignment="center"
                        :marker="{
                          lat: booking.vessel.latitude,
                          lng: booking.vessel.longitude,
                        }"
                      >
                        <div>
                          <div class="pulse">
                            <v-icon
                              color="teal"
                              :style="{
                                rotate: `${booking.vessel.course - 45}deg`,
                                'z-index': 10000,
                              }"
                              >near_me</v-icon
                            >
                          </div>
                        </div>
                      </gmap-custom-marker>
                    </div>
                  </gmap-map>
                </v-col>
              </v-row>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import GmapCustomMarker from "vue2-gmap-custom-marker";
import TransportDetail from "./TransportDetail.vue";

export default {
  props: {
    booking: {
      type: Object,
      required: true,
    },
    organisations: {
      type: Array,
      required: true,
    },
    transportLocations: {
      type: Array,
      required: true,
    },
  },
  components: {
    GmapCustomMarker,
    TransportDetail,
  },
  data: () => ({
    mapCenter: { lat: 0, lng: 0 },
    zoom: 1.8,
    mapStyle: [
      {
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#bdbdbd",
          },
        ],
      },
      {
        featureType: "poi",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          {
            color: "#c1d6e0",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "transit",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#3e3e3e",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
    ],
    displayVessel: true,
    legColours: ["#2196f3", "#4caf50", "#ff9800", "#f44336"],
    refreshMap: 200,
    routes: null,
    transportKey: 100,
  }),
  watch: {
    "booking.sailingScheduleId": {
      handler: function (val) {
        if (val) {
          this.calculateRoutes();
        }
      },
      immediate: true,
    },
  },
  methods: {
    async calculateRoutes() {
      if (this.booking && this.booking.vessel && this.booking.sailingSchedule) {
        let routes = await this.$API.getBookingEmissions(this.booking.id, {
          params: {
            vesselId: this.booking.vessel.id,
            sailingScheduleId: this.booking.sailingScheduleId,
          },
        });
        this.routes = routes;
      }
    },
  },
};
</script>
<style scoped>
* {
  font-size: 12px;
  --darkLayer: var(--v-greyDarker-base) !important;
  --lighterLayer: var(--v-greyRaised-lighten1) !important;
}
.infoCards {
  background: var(--lighterLayer) !important ;
  contain: content;
  border-radius: 10px;
}

.innerContent {
  max-height: calc(100vh - 36px - 30px) !important;
  height: 100%;
  width: 100%;
  contain: content;
  overflow-y: scroll;
}
</style>
